<template>
  <div class="work-order-gather">
    <van-nav-bar left-arrow left-text="返回" @click-left="goBack" title="师傅安装工单统计" />
    <!-- <div>{{ text.code }}</div>
    <div>{{ text.msg }}</div>
    <div>{{ text.time }}</div> -->
    <div class="header" v-if="isHide">
      <van-popover v-model="showPopover" trigger="click" :actions="actions" @select="onSelect">
        <template #reference>
          <van-button type="info" size="small">{{ role_typeText }} <span class="selectOrigin">></span></van-button>
        </template>
      </van-popover>
      <input @keyup.enter="keydownFn" v-model="keyword" type="text" class="inputClass" />
      <van-button @click="downLoadFn" type="info" size="small">下载报表 </van-button>
      <img @click="showFn" class="svgClass" src="@/assets/icon/hspIcon.svg" />
    </div>
    <div :class="isHide ? '' : 'widDiv'">
      <div @scroll="scrollFn" class="table_header" :style="isHide ? 'height: calc(100vh - 46px - 53.333px);' : ' height: 100vw;'">
        <div v-for="(item, index) in dataList" :key="index">
          <div class="header wrap" style="width:200px">{{ item.websit_name }}{{ item.websit_number }}</div>
          <div class="header wrap" style="width:150px">{{ item.name }}{{ item.number }}</div>

          <div class="header wrap">{{ item.appoint_reserve_today }}</div>
          <div class="header wrap">{{ item.appoint_reserve_machine_today }}</div>
          <div class="header wrap">{{ item.completed_today }}</div>
          <div class="header wrap">{{ item.completed_machine_today }}</div>
          <div class="header wrap">{{ item.unfinished_today }}</div>
          <div class="header wrap">{{ item.unfinished_machine_today }}</div>
          <div class="header wrap">{{ item.unfinished_all }}</div>
          <div class="header wrap">{{ item.unfinished_machine_all }}</div>
          <div class="header wrap">{{ item.no_appointment_all }}</div>
        </div>
      </div>
      <!-- <div>yi</div> -->
    </div>
    <!-- <van-overlay :show="isShow">
      <div class="wrapper">
        <van-loading color="#0094ff" size="24px" vertical></van-loading>
      </div>
    </van-overlay> -->
  </div>
</template>

<script>
import { Toast, Notify } from 'vant'
import { getUrlKey, debounce, getBlob } from '@/utils/common'
export default {
  data() {
    return {
      isShow: false,

      finished: false,

      pageSize: 50,
      pageNum: 1,

      keyword: '',
      show: false,
      isHide: true,
      columns: ['杭州', '宁波', '温州', '绍兴', '湖州', '嘉兴', '金华', '衢州'],
      showPopover: false,
      role_type: 'websit',
      role_typeText: '网点',
      // 通过 actions 属性来定义菜单选项
      actions: [
        { text: '师傅', value: 'worker' },
        { text: '网点', value: 'websit' }
      ],
      dataList: [
        {
          websit_name: '网点信息',
          websit_number: '',
          name: '师傅信息',
          number: '',
          appoint_reserve_today: '当日预约派工单量',
          appoint_reserve_machine_today: '当日预约派工单台数',
          completed_today: '当日完工单数',
          completed_machine_today: '当日完工台数',
          unfinished_today: '当日未完工单量',
          unfinished_machine_today: '当日未完工单台数',
          unfinished_all: '累计未完工单量',
          unfinished_machine_all: '累计未完工单台数',
          no_appointment_all: '未预约工单'
        }
      ]
    }
  },
  created() {
    this.onLoad()
  },
  methods: {
    debounceChange: debounce(function(val, val2) {
      if (val < 300 && val2 >= 0 && this.finished === false) {
        this.onLoad()
      }
    }, 500),
    scrollFn(value) {
      const aaa = value.target.scrollHeight - value.target.scrollTop - value.target.clientHeight
      const bbb = value.target.scrollLeft
      this.debounceChange(aaa, bbb)
    },
    onLoad() {
      // this.isShow = true
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
        duration: 0
      })
      let params = {
        openid: getUrlKey('openid', window.location.href),
        role_type: 'worker',
        keyword_type: this.role_type,
        keyword: this.keyword,
        order_type: 'install',
        export: '0',
        page: this.pageNum++,
        page_size: this.pageSize,
        token: JSON.parse(sessionStorage.getItem('mobileOfficeMiniTOKEN'))
      }
      this.postRequestPHP('/services/statistics/order_statistics/lists', params)
        .then(res => {
          // console.log(res)
          this.dataList.push(...res.data.data)

          if (this.dataList.length >= res.data.total) {
            this.finished = true
            Notify({ type: 'primary', message: '已加载全部数据' })
          }
          // this.isShow = false
          Toast.clear()
        })
        .catch(err => {
          Notify({ type: 'danger', message: '参数错误' })
          Toast.clear()
        })
    },
    keydownFn() {
      this.resetFn()
    },
    downLoadFn() {
      //   this.getList()
      let params = {
        openid: getUrlKey('openid', window.location.href),
        role_type: 'worker',
        keyword_type: this.role_type,
        keyword: this.keyword,
        order_type: 'install',
        export: '1',
        page: 1,
        page_size: this.pageSize * this.pageNum,
        token: JSON.parse(sessionStorage.getItem('mobileOfficeMiniTOKEN'))
      }
      this.postRequestPHP('/services/statistics/order_statistics/lists', params).then(res => {
        // uni.postMessage({
        //   data: { url: res.data.filename.file, name: res.data.filename.name }
        // })
        // console.log('pages/master/mine/download?url=' + res.data.filename.file + '&name=' + res.data.filename.name)
        uni.navigateTo({
          url: '/pages/master/mine/download?url=' + res.data.filename.file + '&name=' + res.data.filename.name
        })
        // console.log(res)
        // window.open(res.data.filename.file)
        // getBlob({ url: res.data.filename.file, name: res.data.filename.name })
      })
    },

    // getList() {
    //   let params = {
    //     openid: getUrlKey('openid', window.location.href),
    //     role_type: this.role_type,
    //     keyword_type: this.role_type,
    //     keyword: this.keyword,
    //     order_type: 'install',
    //     export: '0',
    //     token: JSON.parse(sessionStorage.getItem('mobileOfficeMiniTOKEN'))
    //   }

    //   this.postRequestPHP('/services/statistics/order_statistics/lists', params).then(res => {
    //     console.log(res)
    //     this.text = res
    //     let oneTitle = {
    //       websit_name: '网点信息',
    //       websit_number: '',
    //       name: '师傅信息',
    //       number: '',
    //       appoint_reserve_today: '当日预约派工单量',
    //       appoint_reserve_machine_today: '当日预约派工单台数',
    //       completed_today: '当日完工单数',
    //       completed_machine_today: '当日完工台数',
    //       unfinished_today: '当日未完工单量',
    //       unfinished_machine_today: '当日未完工单台数',
    //       unfinished_all: '累计未完工单量',
    //       unfinished_machine_all: '累计未完工单台数',
    //       no_appointment_all: '未预约工单'
    //     }
    //     this.dataList = [oneTitle, ...res.data]
    //   })
    // },
    showFn() {
      this.isHide = false
      // this.resetFn()
      // this.finished = false
    },
    selectFn(value) {
      this.show = true
    },

    onSelect(value) {
      this.role_type = value.value
      this.role_typeText = value.text
      this.resetFn()
    },

    goBack() {
      if (this.isHide) {
        this.$router.back()
      } else {
        this.isHide = true
        // this.resetFn()
      }
    },
    resetFn() {
      this.dataList = [
        {
          websit_name: '网点信息',
          websit_number: '',
          name: '师傅信息',
          number: '',
          appoint_reserve_today: '当日预约派工单量',
          appoint_reserve_machine_today: '当日预约派工单台数',
          completed_today: '当日完工单数',
          completed_machine_today: '当日完工台数',
          unfinished_today: '当日未完工单量',
          unfinished_machine_today: '当日未完工单台数',
          unfinished_all: '累计未完工单量',
          unfinished_machine_all: '累计未完工单台数',
          no_appointment_all: '未预约工单'
        }
      ]
      this.pageNum = 1
      this.finished = false
      this.onLoad()
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.selectOrigin {
  display: inline-block;
  transform: rotate(90deg);
}
.svgClass {
  //   font-size: 20px;
  height: 24px;
  width: 24px;
}

.table_header {
  overflow: scroll;
  width: 100%;
  //   height: 100vw;
  border-top: 4px solid #efefef;
  white-space: nowrap;

  .header {
    overflow: hidden;
    border-right: 4px solid #efefef;
    display: inline-block;
    width: 120px;
    height: 50px;
    font-size: 14px;
    // background-color: #ce1919;
    // padding: 0;
  }
  .wrap {
    white-space: pre-wrap;
    width: 50px;
  }
  .header2 {
    overflow: hidden;
    border-right: 4px solid #efefef;
    display: inline-block;
    width: 180px;
    height: 20px;
    font-size: 14px;
    // background-color: #ce1919;
    // padding: 0;
  }
  .website {
    position: sticky;
    left: 0;
  }
  .worker {
    position: sticky;
    left: 224px;
  }
}

.listTitle {
  font-weight: 700;
  font-size: 12px;
}

.widDiv {
  width: calc(100vh - 46px);
  height: calc(100vh - 46px);
  transform: rotate(90deg);
  transform-origin: 50vw 50vw;
}
.selectStyle {
  height: 30px;
  border-radius: 5px;
  border: 1px solid #dadada;
}
.inputClass {
  height: 30px;
  border: 1px solid #dddddd;
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  padding: 10px;
}
</style>
